<template>
  <div>
    <announcement-form></announcement-form>

    <h1 class="title has-text-centered mt-6">All Announcements</h1>
    <b-table
      :data="announcements.data"
      :striped="true"
      :hoverable="true"
      :loading="loadingAnnouncements"
      class="mt-4 topic-table"
      @click="goToAnnouncement"
      :row-class="(row, index) => !row.active && 'is-danger'"
    >
      <b-table-column field="name" label="Title" v-slot="props">
        <p>
          <strong>{{ props.row.title }}</strong>
          <br />
          {{ props.row.text }}
        </p>
      </b-table-column>

      <b-table-column field="created_at" label="Created" v-slot="props">
        <p>{{ props.row.created_at | dateStandard }}</p>
      </b-table-column>

      <b-table-column field="display_duration" label="Duration" v-slot="props">
        <p>{{ props.row.display_duration }}</p>
      </b-table-column>
    </b-table>

    <b-pagination
      class="mt-4"
      v-model="announcements.current_page"
      :total="announcements.total"
      :per-page="announcements.per_page"
      range-before="3"
      range-after="3"
      order="is-centered"
      icon-pack="fas"
      @change="handlePageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page">
    </b-pagination>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AnnouncementForm from '@/components/Announcement/AnnouncementForm.vue';

export default {
  name: 'home',
  components: { AnnouncementForm },
  computed: {
    ...mapState('announcement', ['loadingAnnouncements', 'announcements']),
  },
  mounted() {
    this.$store.dispatch('announcement/loadAnnouncements', 1);
  },
  methods: {
    goToAnnouncement(row) {
      this.$router.push({ path: `/announcements/${row.id}` });
    },
    handlePageChange(pageNum) {
      this.$store.dispatch('announcement/loadAnnouncements', pageNum);
    },
  },
};
</script>

<style>
.topic-table tr {
  cursor: pointer;
}
</style>
